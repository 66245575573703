import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbars from './components/Navbar';
import Login from './pages/login';
import { useState } from 'react';
import UserContext from './context/usercontext';
import './index.css';
// import Sidebar from './sidebar';
import Home from './pages/Home';
import AddNew from './pages/workout/AddNew';
import SingleUser from './pages/users/singleUser';
import Booking from './pages/users/booking';
import UserList from './pages/users/user';
import EditWorkout from './pages/workout/edit';
import ActiveList from './pages/workout/showActiveWorkouts';
import Support from './pages/users/support';
import GenerateWorkOut from './pages/plan/newPlan';
import AddWorkout from './pages/workout/Add';
import EmailSender from './pages/users/email';
import SessionIndex from './pages/content/sessions';
import EditSession from './pages/content/sessions/edit';
import BlogPostComponent from './pages/blog/post';
import EditPlan from './pages/plan/EditPlan';
import UserMainPage from './pages/users';
import BeforeAfter from './pages/users/singleUser/BeforeAfter';
import OrderIndex from './pages/orders/OrderIndex';
import SinglePlan from './pages/plan/SinglePlan';
import EditDay from './pages/plan/EditDay';
import TrainerForm from './pages/trainer/AddTrainer';
import AddSession from './pages/content/AddSession';
import UserPhotoGallery from './pages/users/UserImages';
import AppUserMainPage from './pages/AppUsers';
import AppSingleUser from './pages/AppUsers/singleUser';
import Tools from './pages/tools';

function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('credentials'))
      ? JSON.parse(localStorage.getItem('credentials'))
      : null
  );

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser }}>
        {user?.role === 'admin' ? <Navbars /> : null}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="excercises" element={<ActiveList />} />
          <Route path="emailsender" element={<EmailSender />} />
          <Route path="e" element={<AddNew />} />
          <Route path="newplan" element={<AddWorkout />} />
          <Route path="editworkout" element={<EditWorkout />} />
          <Route path="users" element={<UserMainPage />} />
          <Route path="users/:id" element={<UserList />} />
          <Route path="tickets" element={<Support />} />
          <Route path="booking" element={<Booking />} />
          <Route path="login" element={<Login />} />
          <Route path="user/:id" element={<SingleUser />} />
          <Route path="appuser/:id" element={<AppSingleUser />} />
          <Route path="generate" element={<GenerateWorkOut />} />
          <Route path="newplans" element={<ActiveList />} />
          <Route path="Sessions" element={<SessionIndex />} />
          <Route path="Sessions/Session/:id" element={<EditSession />} />
          <Route path="createpost" element={<BlogPostComponent />} />
          <Route path="EditPlan" element={<EditPlan />} />
          <Route path="singlePlan/:id" element={<SinglePlan />} />
          <Route path="user/:id/beforeafter" element={<BeforeAfter />} />
          <Route path="orders" element={<OrderIndex />} />
          <Route path="EditDay/:id" element={<EditDay />} />
          <Route path="addtrainer" element={<TrainerForm />} />
          <Route path="Sessions/addvideo" element={<AddSession />} />
          <Route path="UserPhotoGallery" element={<UserPhotoGallery />} />
          <Route path="appusers" element={<AppUserMainPage />} />
          <Route path="tools" element={<Tools />} />
        </Routes>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
