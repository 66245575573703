import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import api from '../../apis/api';

const AddSession = () => {
  const [formData, setFormData] = useState({
    name: '',
    product: '',
    session: 0,
    description: '',
    link: '',
    attachments: [],
    additionalLinks: [],
  });
  const [status, setStatus] = useState('idle');
  console.log(formData);
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const workshops = ['Shape up 6', 'Shape up pro 5'];

  // Handle file uploads for video and attachments
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'attachments') {
      setFormData((prevData) => ({
        ...prevData,
        attachments: Array.from(files),
      }));
    } else if (name === 'video') {
      setFormData((prevData) => ({
        ...prevData,
        video: files[0],
      }));
    }
  };

  // Handle adding additional links
  const handleAddLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      additionalLinks: [...prevData.additionalLinks, ''],
    }));
  };

  const handleLinkChange = (index, e) => {
    const newLinks = [...formData.additionalLinks];
    newLinks[index] = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      additionalLinks: newLinks,
    }));
  };

  const handleSubmit = async (e) => {
    setStatus('loading');
    e.preventDefault();
    try {
      await api.post('/sessionvideo', {
        formData,
      });
      setStatus('success');
      alert('محتوا بارگزاری شد');
    } catch {
      setStatus('error');
    }
  };

  return (
    <Container
      dir="rtl"
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2>ایجاد جلسه جدید</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>نام جلسه</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formProduct">
              <Form.Label>نام دوره</Form.Label>
              <Form.Select
                name="product"
                onChange={handleChange}
                aria-label="Default select example">
                <option>یک گزینه را انتخاب کنید</option>
                {workshops.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
              {/* <Form.Control
                type="text"
                name="product"
                value={formData.product}
                onChange={handleChange}
                required
              /> */}
            </Form.Group>

            <Form.Group controlId="formDuration">
              <Form.Label>شماره جلسه</Form.Label>
              <Form.Control
                type="number"
                name="session"
                value={formData.session}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>توضیحات</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formVideo">
              <Form.Label>آدرس ویدیو</Form.Label>
              <Form.Control
                type="text"
                name="link"
                value={formData.link}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAttachments">
              <Form.Label>پیوستها</Form.Label>
              <Form.Control
                type="file"
                name="attachments"
                onChange={handleFileChange}
                multiple
                accept="image/*,application/pdf"
              />
            </Form.Group>

            <Form.Group controlId="formAdditionalLinks">
              <Form.Label>لینکهای اضافی</Form.Label>
              {formData.additionalLinks.map((link, index) => (
                <Form.Control
                  key={index}
                  type="url"
                  onChange={(e) => handleLinkChange(index, e)}
                  required
                />
              ))}
              <Button variant="link" onClick={handleAddLink}>
                اضافه کردن لینک جدید
              </Button>
            </Form.Group>

            <Button variant="primary" type="submit">
              ایجاد جلسه
            </Button>
          </Form>
          {status === 'loading' && <p>در حال ارسال...</p>}
          {status === 'error' && <p>خطا در ارسال</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default AddSession;
